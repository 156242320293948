import React, { useEffect } from 'react';

import { App } from '@capacitor/app';
const AppUrlListener = () => {
    
    useEffect(() => {
      
        App.addListener('appUrlOpen', data => {
            console.log('App opened with URL:', data);
          });
//       App.addListener('appUrlOpen', (event) => {
//         // Example url: https://beerswift.app/tabs/tab2
//         // slug = /tabs/tab2
//         console.log('Event-->',event)
//         const slug = event.url.split('.com').pop();
//         if (slug) {
// alert('Url')
// console.log(slug)
// }
//         // If no match, do nothing - let regular routing
//         // logic take over
//       });
    }, []);
  
    return null;
  };
  
  export default AppUrlListener;