import { FETCH_REGISTER_DATA_REQUEST, FETCH_REGISTER_DATA_SUCCESS, FETCH_REGISTER_DATA_FAILURE ,FETCH_REGISTER_DATA_CLEAR} from './RegisterTypes'
const initialState = {
    loading: false,
    data: '',
    error: '',

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REGISTER_DATA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_REGISTER_DATA_SUCCESS:
            
            return {
                loading: false,
                data: action.payload.message,

            }
        case FETCH_REGISTER_DATA_FAILURE:
            return {
                loading: false,
                error: action.payload.message
            }
        case FETCH_REGISTER_DATA_CLEAR:
                return {
                    loading: false,
                    data:action.payload.message,
                    error: action.payload.message
                }


        default:
            return state;
    }
}
export default reducer;