// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA3UijkqMixMnqH4GlvLVmfdYV5Xy9Z_gM",
  authDomain: "mpower-chat.firebaseapp.com",
  databaseURL: "https://mpower-chat.firebaseio.com",
  projectId: "mpower-chat",
  storageBucket: "mpower-chat.appspot.com",
  messagingSenderId: "71217564725",
  appId: "1:71217564725:web:66e30400d41c9e3aa2757c",
  measurementId: "G-98ZT6NX6TN"
};

// Initialize Firebase
const fbconfig = initializeApp(firebaseConfig);

export default fbconfig


