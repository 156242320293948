
import { getMessaging, onMessage } from "firebase/messaging";
import { useNavigate } from 'react-router-dom';
import fbconfig from '../Config/FirebaseConfig';
import { Capacitor } from '@capacitor/core';
import Swal from 'sweetalert2';


const FirebaseNotification = () => {
    const navigate = useNavigate()
    if (!Capacitor.isNativePlatform()) {

        const messaging = getMessaging(fbconfig);

        onMessage(messaging, (payload) => {
            console.log(payload)
            Swal.fire({
                position: 'center',
                title: 'MyAcuiZen',
                text: payload.notification.title,
                showConfirmButton: true,
                confirmButtonText: 'Open',
                showCancelButton: true,
                confirmButtonColor: '#003153',
                cancelButtonText: 'Cancel'
            }).then((value) => { document.getElementById('reminder').click(); value.isConfirmed ? navigate('/notification') : console.log('cancel') })

            // ...
        });

        
    }
    return null;

}

export default FirebaseNotification

