import React, { lazy, Suspense, useEffect } from 'react'

import './assets/css/App.css';
import AppUrlListener from './components/DeepLinking';
import FirebaseNotification from './components/FirebaseNotification';
import {
  BrowserRouter,
  Routes,
  Route,

} from "react-router-dom";


const Unit = lazy(() => import('./pages/Unit'))
const PdfViewers = lazy(() => import('./pages/PdfViewer'))
const Login = lazy(() => import('./pages/Login'))
const SignUp = lazy(() => import('./pages/SignUp'))
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Layout = lazy(() => import('./pages/Layout'));
const Area = lazy(() => import('./pages/Area'));
const Fetch = lazy(() => import('./pages/FetchSteps'));
const Verify = lazy(() => import('./pages/Verify'));
const Discover = lazy(() => import('./pages/Discover'));
const Discoverbylang = lazy(() => import('./pages/Discoverbylang'));
const Discoverbycategory = lazy(() => import('./pages/Discoverbycategory'));
const DiscoveredArea = lazy(() => import('./pages/DiscoveredArea'));
const Topic = lazy(() => import('./pages/topic'));
const Search = lazy(() => import('./pages/Search'));
const Instructions = lazy(() => import('./pages/instructions'));
const FavouriteCategory = lazy(() => import('./pages/FavouriteCategory'));
const FavouriteLanguage = lazy(() => import('./pages/FavouriteLanguage'));
const Feedback = lazy(() => import('./pages/Feedback'))
const FavouriteArea = lazy(() => import('./pages/FavouriteArea'));
const FavouriteEnterprise = lazy(() => import('./pages/FavouriteEnterprises'));
const FavouriteWork = lazy(() => import('./pages/FavouriteWork'));
const Profile = lazy(() => import('./pages/Profile'));
const Recent = lazy(() => import('./pages/Recent'));
const Notification = lazy(() => import('./pages/Notification'));

const Paused = lazy(() => import('./pages/paused'));

function App() {

  return (
    <div className="App" >
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <AppUrlListener></AppUrlListener>
          <FirebaseNotification ></FirebaseNotification>
          <Routes >

            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />

            <Route path="/signup" element={<SignUp />} />
            <Route path="/dashboard" element={<Layout />}>
              <Route index element={<Dashboard />} />
            </Route>
            <Route path="/area" element={<Layout />}>
              <Route path=':id' element={<Area />} />
            </Route>
            <Route path="/unit" element={<Layout />}>
              <Route path=':id' element={<Unit />} />
            </Route>
            <Route path="/fetch" element={<Layout />}>
              <Route path=':id' element={<Fetch />} />
            </Route>
            <Route path="/discover" element={<Layout />}>
              <Route index element={<Discover />} />
            </Route>
            <Route path="/profile" element={<Layout />}>
              <Route path="/profile" element={<Profile />} />

            </Route>
            <Route path="/feedback" element={<Layout />}>
              <Route path="/feedback" element={<Feedback />} />

            </Route>
            <Route path="/notification" element={<Layout />}>
              <Route path="/notification" element={<Notification />} />

            </Route>
            <Route path="/verify/:id/:id1" element={<Verify />} />
            <Route path="/PdfViewer" element={<Layout />}>
              <Route path="/PdfViewer" element={<PdfViewers />} />
            </Route>
            <Route path="/favouritearea" element={<Layout />}>
              <Route path="/favouritearea" element={<FavouriteArea />} />
            </Route>
            <Route path="/favouriteenterprise" element={<Layout />}>
              <Route path="/favouriteenterprise" element={<FavouriteEnterprise />} />
            </Route>
            <Route path="/paused" element={<Layout />}>
              <Route path="/paused" element={<Paused />} />
            </Route>
            <Route path="/favouritework" element={<Layout />}>
              <Route path="/favouritework" element={<FavouriteWork />} />
            </Route>
            <Route path="/discoverlang" element={<Layout />}>
              <Route index element={<Discoverbylang />} />
            </Route>

            <Route path="/discovercategory" element={<Layout />}>
              <Route index element={<Discoverbycategory />} />
            </Route>
            <Route path="/discoverarea" element={<Layout />}>
              <Route index element={<DiscoveredArea />} />
            </Route>
            <Route path="/topic" element={<Layout />}>
              <Route index element={<Topic />} />
            </Route>
            <Route path="/search" element={<Layout />}>
              <Route index element={<Search />} />
            </Route>
            <Route path="/instructions" element={<Layout />}>
              <Route path="/instructions" element={<Instructions />} />
            </Route>
            <Route path="/favouritecat" element={<Layout />}>
              <Route path="/favouritecat" element={<FavouriteCategory />} />
            </Route>
            <Route path="/favouritelan" element={<Layout />}>
              <Route path="/favouritelan" element={<FavouriteLanguage />} />
            </Route>
            <Route path="/recent" element={<Layout />}>
              <Route path="/recent" element={<Recent />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
