import { createStore, applyMiddleware, compose } from 'redux'
import reduxThunk from 'redux-thunk'
import logger from 'redux-logger'
import rootReducer from './rootReducer'

const middleware = [reduxThunk];

if (process.env.NODE_ENV === 'development') {
    middleware.push(logger)
}
const store = createStore(rootReducer, compose(applyMiddleware(...middleware)));
export default store;